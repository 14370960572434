var exports = {};
function r(e) {
  var o,
    t,
    f = "";
  if ("string" == typeof e || "number" == typeof e) f += e;else if ("object" == typeof e) if (Array.isArray(e)) {
    var n = e.length;
    for (o = 0; o < n; o++) e[o] && (t = r(e[o])) && (f && (f += " "), f += t);
  } else for (t in e) e[t] && (f && (f += " "), f += t);
  return f;
}
function e() {
  for (var e, o, t = 0, f = "", n = arguments.length; t < n; t++) (e = arguments[t]) && (o = r(e)) && (f && (f += " "), f += o);
  return f;
}
exports = e, exports.clsx = e;
export default exports;
export const clsx = exports.clsx;